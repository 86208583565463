import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    userName: '',
    email: '',
    authority: [],
    token:''
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
        startRegistration: (state) => {
            state.loading = true;
            state.registrationError = '';
          },
          registrationSuccess: (state) => {
            state.loading = false;
            state.registrationError = '';
          },
          registrationError: (state, action) => {
            state.loading = false;
            state.registrationError = action.payload;
          },
    },
})

export const { setUser, startRegistration, registrationSuccess, registrationError, } = userSlice.actions

export default userSlice.reducer

