import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/user/signIn`,
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/user/`,
        method: 'post',
        data,
    })
}
export async function apiForgetPasswordOtp(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/otp/forgetPassword`,
        method: 'post',
        data,
    })
}
export async function apiForgetPasswordOtpVerification(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/otp/verify`,
        method: 'patch',
        data,
    })
}
export async function apiResetLostPassword(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/user/resetPassword`,
        method: 'patch',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/user/:userId/resetPassword`,
        method: 'patch',
        data,
    })
}

export async function apiSendOtp(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/otp`,
        method: 'post',
        data,
    })
}
export async function apiSendOtpForgetPassword(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/forgetPassword`,
        method: 'post',
        data,
    })
}

export async function apiVerifyOtp(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/otp/verify`,
        method: 'patch',
        data,
    })
}


