import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    countries: [],
    userTypes: [],
    userTypeId: [],
    grades: [],
    studyTypes: [],
    years: [],
    sessions: [],
    variants: [],
    boards: [],
    subjects: [],
    gradess: [],
    level: [],
    papers: [],
    user_id: null,
    otp: null,
    selectedCountry: null,
    SearchData: [],
    totalSearch: '',
    SearchQuery: [],
    QuestionData: {},
    ProgressPercent: '',
    QuestionPagination: '',
    QuestionCurrentPage: '',
    PdfUrl: '',


}

export const data = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setCountriesAction: (state, action) => {
            state.countries = action.payload
        },
        setSelectedCountryAction: (state, action) => {
            //  console.log(action.payload)
            state.selectedCountry = action.payload;
        },
        // grades for registration
        setGradesAction: (state, action) => {
            state.grades = action.payload
        },
        setUserTypesAction: (state, action) => {
            state.userTypes = action.payload
        },
        setUserTypeidAction: (state, action) => {
            state.userTypeId = action.payload
        },
        setStudyTypesAction: (state, action) => {
            state.studyTypes = action.payload
        },
        setyearsAction: (state, action) => {
            state.years = action.payload
        },
        setsessionsAction: (state, action) => {
            // console.log("section " , action.payload)
            state.sessions = action.payload
        },
        setvariantsAction: (state, action) => {
            // console.log("section " , action.payload)
            state.variants = action.payload
        },
        setlevelssAction: (state, action) => {
            state.level = action.payload
        },
        setboardsAction: (state, action) => {
            // console.log("section " , action.payload)
            state.boards = action.payload
        },
        setsubjectsAction: (state, action) => {
            // console.log("section " , action.payload)
            state.subjects = action.payload
        },
        setpapersAction: (state, action) => {
            // console.log("section " , action.payload)
            state.papers = action.payload
        },
        //search for paper
        setgradesAction: (state, action) => {
            state.gradess = action.payload
        },
        setSearchDataAction: (state, action) => {
            state.SearchData = action.payload
        },
        setUserIdAction: (state, action) => {
            // console.log(action.payload)
            state.user_id = action.payload
        },
        openDialog: (state) => {
            state.dialogOpen = true
        },
        closeDialog: (state) => {
            state.dialogOpen = false
            state.ticketId = ''
            state.board = ''
            state.dialogView = ''
        },
        setOtp: (state, action) => {
            // console.log(action.payload)
            state.otp = action.payload
        },
        settotalSearchAction: (state, action) => {
            // console.log(action.payload)
            state.totalSearch = action.payload
        },
        setSearchQueryAction: (state, action) => {
            // console.log(action.payload)
            state.SearchQuery = action.payload
        },

        setQuestionDataAction: (state, action) => {
            console.log("Question Data", action.payload);
            state.QuestionData = action.payload
            // state.QuestionData = [...state.QuestionData, ...action.payload];
        },
        setProgressPercentAction: (state, action) => {
            // console.log(action.payload);
            state.ProgressPercent = action.payload

        },
        setQuestionPaginationtAction: (state, action) => {
            // console.log(action.payload);
            state.QuestionPagination = action.payload

        },
        setQuestionCurrentPageAction: (state, action) => {
            // console.log(action.payload);
            state.QuestionCurrentPage = action.payload

        },
        setPdfUrlAction: (state, action) => {
            // console.log(action.payload);
            state.PdfUrl = action.payload

        },


    },
})

export const { setCountriesAction, setPdfUrlAction, setSearchQueryAction, setQuestionCurrentPageAction, setProgressPercentAction, setQuestionPaginationtAction, setQuestionDataAction, setSearchDataAction, settotalSearchAction, setlevelssAction, setpapersAction, setgradesAction, setsessionsAction, setsubjectsAction, setboardsAction, setvariantsAction, setSelectedCountryAction, setGradesAction, setUserTypesAction, setStudyTypesAction, setUserTypeidAction, setyearsAction, setUserIdAction, openDialog, closeDialog, setOtp } = data.actions

export default data.reducer
