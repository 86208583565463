import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  userData: [],
  UserchangeCountry: null,
  Userchangegrade: null,
  userchangeStudyType: null,
  Userlimits: null,
  Userfreetraillimits: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUserchangeCountryAction: (state, action) => {
      // console.log(action.payload)
      state.UserchangeCountry = action.payload;
    },
    setUserchangegradeAction: (state, action) => {
      console.log(action.payload)
      state.Userchangegrade = action.payload;
    },
    setUserchangeStudyTypeAction: (state, action) => {
      console.log(action.payload)
      state.userchangeStudyType = action.payload;
    },
    setUserDeviceLimit: (state, action) => {
      // console.log(action.payload)
      state.Userlimits = action.payload;
    },
    setUserFreeTrailLimit: (state, action) => {
      // console.log(action.payload)
      state.Userfreetraillimits = action.payload;
    },
  },
});

export const { setUserData, setUserchangeCountryAction, setUserchangeStudyTypeAction, setUserchangegradeAction, setUserDeviceLimit, setUserFreeTrailLimit } = userSlice.actions;

export default userSlice.reducer;
