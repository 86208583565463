const appConfig = {
    apiPrefix: '/api',
    // authenticatedEntryPath: '/home',
    authenticatedEntryPath: '/qaSearch',
    authenticatedPaymentMethodPath: '/payment-method',
    // unAuthenticatedEntryPath: '/sign-in',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: true,
}

export default appConfig
