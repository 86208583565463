import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiForgetPasswordOtp, apiForgetPasswordOtpVerification, apiResetLostPassword, apiSignIn, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { setUserIdAction } from 'store/sharedData/data'
import { toast, Notification } from 'components/ui'
import HandlingSuccessStatusService from 'utils/HandlingSuccessStatusService'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.data) {
                console.log("sign in response", resp.data);
                const token = resp.data.data.token
                dispatch(onSignInSuccess(token))
                if (resp.data.success === true) {
                    toast.push(
                        <Notification
                            title={'Sign-in successful'}
                            duration={2500}
                            type={'success'}
                        >
                            Welcome to past papers website
                        </Notification>,
                        {
                            placement: 'top-center',
                        }
                    );
                    dispatch(
                        setUser(
                            {
                                avatar: '',
                                userName: `${resp.data.data.userData.first_name} ${resp.data.data.userData.last_name}`,
                                // authority: resp.data.data.userData.user_type_id === 1
                                //     ? (resp.data.data.userData.phone === '01234567890' || resp.data.data.userData.phone === '01112223330'
                                //         ? ['support_admin']
                                //         : ['admin'])
                                //     : ['user'],
                                authority: resp.data.data.userData.user_type_id !== 1
                                    ? ['user'] : ['01234567890', '01112223330'].includes(resp.data.data.userData.phone) ? ['support_admin'] : ['admin'],
                                email: resp.data.data.userData.email,
                                token: resp.data.data.token
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            console.log(errors.response.data.message);


            if (errors.response.status === 500) {
                toast.push(
                    <Notification
                        title={'Internal Server Error'}
                        duration={2500}
                        type={'danger'}
                    >
                        user does not inserted
                    </Notification>,
                    {
                        placement: 'top-center',
                    }
                );


            }
            else if (errors.response.status === 400) {
                toast.push(
                    <Notification
                        title={'Invalid Data'}
                        duration={2500}
                        type={'danger'}
                    >
                        please insert a valid Data
                    </Notification>,
                    {
                        placement: 'top-center',
                    }
                );

            }
            else if (errors.response.status === 401) {
                toast.push(
                    <Notification
                        title={errors.response.data.message}
                        duration={1000}
                        type={'danger'}
                    >
                    </Notification>,
                    {
                        placement: 'top-center',
                    }
                );
            }

            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                // const { token } = resp.data
                console.log('resp.data', resp.data);
                dispatch(setUserIdAction(resp.data.data.userId));
                dispatch(onSignInSuccess(resp.data.data.token));

                // dispatch(onSignInSuccess(token))
                if (resp.data.success === true) {
                    console.log("inside if statement")
                    dispatch(
                        setUser(
                            {
                                avatar: '',
                                userName: `${resp.data.data.userData.firstName} ${resp.data.data.userData.lastName}`,
                                authority: resp.data.data.userData.user_type_id === 1 ? ['admin'] : ['user'],
                                email: resp.data.data.userData.email,
                                token: resp.data.data.token
                            }
                        )
                    )
                    HandlingSuccessStatusService({ message: "User registered successfully", title: ' Success Message', type: "success" });
                }
                /*Add Navigate to Select Payment Method*/
                // navigate(
                //    appConfig.authenticatedPaymentMethodPath
                // )
                // navigate(
                //     appConfig.authenticatedPaymentMethodPath
                // )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            if (errors.response.status === 400) {
                toast.push(
                    <Notification
                        title={'Invalid Date'}
                        duration={2500}
                        type={'danger'}
                    >
                        please insert a valid Data
                    </Notification>,
                    {
                        placement: 'top-center',
                    }
                );

            }
            else if (errors.response.status === 401) {
                toast.push(
                    <Notification
                        title={errors.response.data.message}
                        duration={1000}
                        type={'danger'}
                    >
                    </Notification>,
                    {
                        placement: 'top-center',
                    }
                );
            }
            else if (errors.response.status === 409) {
                toast.push(
                    <Notification
                        title={'Error Message'}
                        duration={3000}
                        type={'danger'}

                    >
                        Email already exists.
                    </Notification>,
                    {
                        placement: 'top-center',
                    }
                );
            }
            else if (errors.response.status === 500) {
                toast.push(
                    <Notification
                        title={'Internal Server Error'}
                        duration={2500}
                        type={'danger'}
                    >
                        user does not inserted
                    </Notification>,
                    {
                        placement: 'top-center',
                    }
                );


            }
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }
    const forgetPasswordOtp = async (values) => {
        try {
            const resp = await apiForgetPasswordOtp(values)
            console.log("response", resp);
            return resp;
        } catch (errors) {
            console.log("forgetPasswordOtp errors", errors)
            return errors.response.data
        }
    }
    const forgetPasswordOtpVerification = async (values) => {
        try {
            const resp = await apiForgetPasswordOtpVerification(values)
            console.log("response", resp);
            return resp;
        } catch (errors) {
            console.log("forgetPasswordOtpVerification errors", errors)
            return errors.response.data
        }
    }
    const resetLostPassword = async (values) => {
        try {
            const resp = await apiResetLostPassword(values)
            console.log("response", resp);
            return resp;
        } catch (errors) {
            console.log("forgetPasswordOtp errors", errors)
            return errors.response.data
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        // await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
        forgetPasswordOtp,
        forgetPasswordOtpVerification,
        resetLostPassword
    }
}

export default useAuth
