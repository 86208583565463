import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    userTypes: [],
    user_id: null,
    AdminchangeUserCountry: null,
    AdminchangeUserGrade: null,
    AdminchangeStudyType: null,
    limits: null,
    downloadslimits: null,
    freetraillimits: null,
    AdminEditUserId: [],
    NewDeviceName: '',
    data: [],
}

export const data = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setUserTypesAction: (state, action) => {
            state.userTypes = action.payload
        },
        setUserIdAction: (state, action) => {
            // console.log(action.payload)
            state.user_id = action.payload
        },
        setAdminchangeUserCountryAction: (state, action) => {
            // console.log(action.payload)
            state.AdminchangeUserCountry = action.payload;
        },
        setAdminchangeUserGradeAction: (state, action) => {
            state.AdminchangeUserGrade = action.payload;
        },
        setAdminchangeStudyTypeAction: (state, action) => {
            state.AdminchangeStudyType = action.payload;
        },
        setDeviceLimit: (state, action) => {
            state.limits = action.payload;
        },
        setDownloadLimit: (state, action) => {
            state.downloadslimits = action.payload;
        },
        setFreeTrailLimit: (state, action) => {
            state.freetraillimits = action.payload;
        },
        setAdminEditUserIdAction: (state, action) => {
            state.AdminEditUserId = action.payload
        },
        setData: (state, action) => {
            state.data = action.payload
        },
        setNewDeviceName: (state, action) => {
            state.NewDeviceName = action.payload
        },
    },
})

export const { setUserTypesAction, setNewDeviceName, setUserIdAction, setAdminchangeStudyTypeAction, setData, setAdminchangeUserCountryAction, setAdminchangeUserGradeAction, setFreeTrailLimit, setDownloadLimit, setDeviceLimit, setAdminEditUserIdAction } = data.actions

export default data.reducer
