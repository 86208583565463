import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'
import data from './sharedData'
import admin from './adminUser/admin'
import user from './adminUser/user'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        locale,
        data,
        admin,
        user,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
