import { toast, Notification } from 'components/ui';
import React from 'react'

function HandlingSuccessStatusService(props) {
    const { message, title, type } = props;
    toast.push(
        <Notification
            title={title}
            duration={3000}
            type={type}
        >
            {message}
        </Notification>,
        {
            placement: 'top-center',
        }
    );
}

export default HandlingSuccessStatusService